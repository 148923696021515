<template>
  <gf-base overflow v-loading="loading">
    <el-row :gutter="20" class="mb-5">
      <el-col :md="8">
        <gf-statwidget3 title="Transaction Stats" type="success">
          <template #box-1-1>
            <div class="stat">
              <div class="stat__icon my-1 ml-n2">
                <span class="svg-icon svg-icon-3x svg-icon-dark">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                        fill="#000000" opacity="0.3" />
                      <path
                        d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                        fill="#000000" />
                      <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1" />
                      <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1" />
                    </g>
                  </svg>
                </span>
                <span class="text-dark font-weight-bold">Draft</span>
              </div>
              <div class="stat__title">
                <span class="text-dark font-weight-bold font-size-h6">
                  {{ $Numeral(data.transactions.draft.count).format('0,0') }}
                </span>
              </div>
            </div>
          </template>
          <template #box-1-2>
            <div class="stat">
              <div class="stat__icon my-1 ml-n2">
                <span class="svg-icon svg-icon-3x svg-icon-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                        fill="#000000" opacity="0.3" />
                      <path
                        d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                        fill="#000000" />
                      <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1" />
                      <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1" />
                    </g>
                  </svg>
                </span>
                <span class="text-primary font-weight-bold">Active</span>
              </div>
              <div class="stat__title">
                <span class="text-primary font-weight-bold font-size-h6">
                  {{ $Numeral(data.transactions.active.count).format('0,0') }}
                </span>
              </div>
            </div>
          </template>
          <template #box-2-1>
            <div class="stat">
              <div class="stat__icon my-1 ml-n2">
                <span class="svg-icon svg-icon-3x svg-icon-success">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                        fill="#000000" opacity="0.3" />
                      <path
                        d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                        fill="#000000" />
                      <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1" />
                      <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1" />
                    </g>
                  </svg>
                </span>
                <span class="text-success font-weight-bold">Finalized</span>
              </div>
              <div class="stat__title">
                <span class="text-success font-weight-bold font-size-h6">
                  {{ $Numeral(data.transactions.finalized.count).format('0,0') }}
                </span>
              </div>
            </div>
          </template>
          <template #box-2-2>
            <div class="stat">
              <div class="stat__icon my-1 ml-n2">
                <span class="svg-icon svg-icon-3x svg-icon-info">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                        fill="#000000" opacity="0.3" />
                      <path
                        d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                        fill="#000000" />
                      <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1" />
                      <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1" />
                    </g>
                  </svg>
                </span>
                <span class="text-info font-weight-bold">Fulfilled</span>
              </div>
              <div class="stat__title">
                <span class="text-info font-weight-bold font-size-h6">
                  {{ $Numeral(data.transactions.fulfilled.count).format('0,0') }}
                </span>
              </div>
            </div>
          </template>
        </gf-statwidget3>
      </el-col>
      <el-col :md="16">
        <el-row :gutter="20" class="mb-5">
          <el-col :md="8">
            <gf-statwidget :data="$Numeral(data.transactions.count).format('0,0')" subtitle="Total Transactions"
              type="primary">
              <template #icon>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                  height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24" />
                    <path
                      d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                      fill="#000000" opacity="0.3" />
                    <path
                      d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                      fill="#000000" />
                    <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1" />
                    <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1" />
                  </g>
                </svg>
              </template>
            </gf-statwidget>
          </el-col>
          <el-col :md="8">
            <gf-statwidget :data="$Numeral(data.transactions.delivery.count).format('0,0')" subtitle="For Delivery"
              type="info">
              <template #icon>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                  height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24" />
                    <path
                      d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                      fill="#000000" opacity="0.3" />
                    <path
                      d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                      fill="#000000" />
                    <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1" />
                    <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1" />
                  </g>
                </svg>
              </template>
            </gf-statwidget>
          </el-col>
          <el-col :md="8">
            <gf-statwidget :data="$Numeral(data.transactions.invoice.count).format('0,0')" subtitle="Pending Invoice"
              type="warning">
              <template #icon>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                  height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24" />
                    <path
                      d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                      fill="#000000" opacity="0.3" />
                    <path
                      d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                      fill="#000000" />
                    <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1" />
                    <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1" />
                  </g>
                </svg>
              </template>
            </gf-statwidget>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :md="12">
            <gf-statwidget2 type="success" title="Sales" subtitle="Total Sales"
              :data="'₱' + $NumberFormat(data.sales.amount)"></gf-statwidget2>
          </el-col>
          <el-col :md="12">
            <gf-statwidget2 type="danger" title="Pending Invoices" subtitle="Total Pending Invoices"
              :data="'₱' + $NumberFormat(data.invoices.amount)"></gf-statwidget2>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="mb-5">
      <el-col :span="8">
        <gf-listwidget2 title="Top Companies" subtitle="Companies with Highest Sales" :items="topCompanies" light
          @row-click="readCompany">
        </gf-listwidget2>
      </el-col>
      <el-col :span="8">
        <gf-listwidget2 title="Pending Payments" subtitle="Companies with Highest Pending Payments"
          :items="pendingPayments" light @row-click="readPayment">
        </gf-listwidget2>
      </el-col>
      <el-col :span="8">
        <gf-listwidget2 title="Top Materials" subtitle="Materials with Highest Orders" :items="products" light
          @row-click="readProduct">
        </gf-listwidget2>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="mb-5">
      <el-col :span="8">
        <gf-listwidget2 title="Low Stock" subtitle="Materials with Low Stock" :items="stocks" light
          @row-click="readStock">
        </gf-listwidget2>
      </el-col>
      <el-col :span="8">
        <gf-listwidget2 title="Pending Invoices" subtitle="List of Pending Invoices" :items="pendingInvoices" light
          @row-click="readInvoice">
        </gf-listwidget2>
      </el-col>
      <el-col :span="8">
        <gf-listwidget2 title="Pending Delivery" subtitle="List of Pending Deliveries" :items="pendingDeliveries" light
          @row-click="readShipment">
        </gf-listwidget2>
      </el-col>
    </el-row>
  </gf-base>
</template>

<style lang="scss" scoped>
.stat {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  &__icon {
    display: flex;
    align-items: center;

    .value {
      flex-grow: 1;
      text-align: right;
    }
  }
}

.circle {
  width: 18px;
  height: 18px;
  border: 2px solid #585d64;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;

  &--half {
    background: linear-gradient(90deg, #585d64 50%, #fff 50%);
  }

  &--full {
    background: #585d64;
  }
}
</style>

<script>
import DashboardService from '@/services/v1/Dashboard'

export default {
  data() {
    return {
      loading: false,
      data: {
        transactions: {
          count: 0,
          draft: {
            count: 0
          },
          active: {
            count: 0
          },
          finalized: {
            count: 0
          },
          fulfilled: {
            count: 0
          },
          delivery: {
            count: 0
          },
          invoice: {
            count: 0
          }
        },
        sales: {
          amount: 0
        },
        invoices: {
          amount: 0
        }
      },
      topCompanies: [],
      pendingPayments: [],
      products: [],
      stocks: [],
      pendingInvoices: [],
      pendingDeliveries: []
    }
  },
  methods: {
    readCompany(index) {
      const company = this.topCompanies[index]
      this.$router.push({ name: 'transaction-view', params: { id: company.id } })
    },
    readPayment(index) {
      const company = this.pendingPayments[index]
      this.$router.push({ name: 'transaction-view', params: { id: company.id } })
    },
    readProduct(index) {
      const product = this.data.products.data[index].variant
      this.$router.push({ name: 'product-variant-detail', params: { id: product.product.id, vid: product.id } })
    },
    readStock(index) {
      const product = this.data.stocks.data[index].variant
      this.$router.push({ name: 'product-variant-detail', params: { id: product.product.id, vid: product.id } })
    },
    readInvoice(index) {
      const invoice = this.data.invoices.data[index]
      this.$router.push({ name: 'invoice-view', params: { id: invoice.sales_order_id, iid: invoice.id } })
    },
    readShipment(index) {
      const delivery = this.data.deliveries.data[index]
      this.$router.push({ name: 'shipment-view', params: { id: delivery.sales_order_id, sid: delivery.id } })
    },
    async getDashboard() {
      try {
        this.loading = true
        const dsService = new DashboardService()
        const result = await dsService.get()
        this.data = result.data
        const type = ['dark', 'primary', 'secondary', 'success', 'info', 'warning', 'danger']

        for (let i = 0; i < this.data.topCompanies.data.length; i++) {
          const item = this.data.topCompanies.data[i]

          const company = {
            id: item.customer.id,
            header: item.customer.name,
            subheader: item.customer.code || item.customer.name,
            quantity: '₱' + this.$NumberFormat(item['sum(paid)']),
            type: type[Math.floor(Math.random() * Math.floor(6))]
          }
          this.topCompanies.push(company)
        }

        for (let i = 0; i < this.data.pendingPayments.data.length; i++) {
          const item = this.data.pendingPayments.data[i]

          const company = {
            id: item.customer.id,
            header: item.customer.name,
            subheader: item.customer.code || item.customer.name,
            quantity: '₱' + this.$NumberFormat(item['sum(total - paid)']),
            type: type[Math.floor(Math.random() * Math.floor(6))]
          }
          this.pendingPayments.push(company)
        }

        for (let i = 0; i < this.data.products.data.length; i++) {
          const item = this.data.products.data[i]
          const product = {
            id: item.variant.id,
            header: this.$StringFormat(item.variant.product.name + ' - ' + item.variant.name, 25),
            subheader: item.variant.sku,
            quantity: 'Qty: ' + item.quantity,
            type: type[Math.floor(Math.random() * Math.floor(6))]
          }
          this.products.push(product)
        }

        for (let i = 0; i < this.data.stocks.data.length; i++) {
          const item = this.data.stocks.data[i]
          const product = {
            id: item.id,
            header: this.$StringFormat(item.variant.product.name + ' - ' + item.variant.name, 25),
            subheader: item.variant.sku || 'No SKU',
            quantity: 'Qty: ' + this.$NumberFormat(item.value - item.commited),
            type: type[Math.floor(Math.random() * Math.floor(6))]
          }
          this.stocks.push(product)
        }

        for (let i = 0; i < this.data.invoices.data.length; i++) {
          const item = this.data.invoices.data[i]

          const due = this.$DateTime.fromISO(item.payment_due)
          const date = this.$DateTime.local()
          const diff = date.diff(due, 'days').toObject()

          const invoice = {
            id: item.id,
            header: item.si_number,
            subheader: `Overdue: ${Math.floor(diff.days > 0 ? diff.days : 0)} Days (${this.$DateFormat(item.payment_due)})`,
            quantity: '₱' + this.$NumberFormat((item.total - item.paid)),
            type: type[Math.floor(Math.random() * Math.floor(6))]
          }
          this.pendingInvoices.push(invoice)
        }

        for (let i = 0; i < this.data.deliveries.data.length; i++) {
          const item = this.data.deliveries.data[i]
          const filtered = this.pendingDeliveries.filter(f => f.header === item.order.so_number)
          const delivery = {
            id: item.id,
            header: item.order.so_number,
            subheader: 'Shipment #' + (filtered.length + 1),
            quantity: 'Qty: ' + item.units,
            type: type[Math.floor(Math.random() * Math.floor(6))]
          }
          this.pendingDeliveries.push(delivery)
        }
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  async mounted() {
    await this.getDashboard()

    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Dashboard' },
      { title: 'Welcome' }
    ])
  }
}
</script>
